.timesheet_root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timesheet_bottom * {
    margin: 10px;
}

.timesheet_table {
    border-collapse: collapse;
}

.timesheet_table td {
    padding-left: 10px;
    padding-right: 10px;
}
.timesheet_table th {
    padding-left: 10px;
    padding-right: 10px;
}

.timesheet_row_normal {

}
.timesheet_row_weekend {
    background: #eeeeee;
}

.inspection_error {
    text-align: center;
    font-weight: bold;
    color: red;
}