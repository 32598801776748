*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}

html {
    height: 100%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
}

img {
    display: block;
}

img,
video {
    max-width: 100%;
    height: auto;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.font-sans {
    font-family: 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 1em
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.gap-1 {
    gap: 1em;
}

.w-full {
    width: 100%;
}

.m-0 {
    margin: 0;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.my-2 {
    margin-bottom: 2em;
    margin-top: 2em;
}

.space {
    margin: 10px;
}

.logo {
    height: 4em;
}

.token {
    width: 424px;
    margin: auto;
}

.error {
    color: red;
    font-weight: bold;
}
